import tech from "./technologies.js"
const projects = [
    {
        "id": 0,
        "title": "Notes and TODOs chrome extension",
        "keys": "recent javascript js chrome extension chromeextension",
        "description": ["Chrome extension that replaces your new tab window with notes and to do lists to help you not forget any important tasks events or information you have."],
        "image": "/img/portfolio/stayOrganised.png",
        "ribbon": ["JS"],
        "alt": "alt",
        "buttons": [
            {
                "src": "https://chrome.google.com/webstore/detail/stay-organised-notes-and/blnkiojdjnjlkddmpfgecfhfllfnghmd",
                "text": "Check out extension"
            },
            {
                "src": "https://github.com/ScaryFrogg/ToDoChromeExtension",
                "text": "Source code"
            }
        ],
        "technologies": [
            tech.js,
        ],
    },
    {
        "id": 1,
        "title": "SOS Android app with Kotlin backend and web client for tracking",
        "keys": "recent java kotlin android ktor maps",
        "description": ["Android app for sending your location in emergencies"],
        "image": "/img/portfolio/sosapp.png",
        "ribbon": ["Java", "Kotlin/Ktor"],
        "alt": "alt",
        "buttons": [
            {
                "src": "https://github.com/ScaryFrogg/SosApp",
                "text": "Android app code"
            },
            {
                "src": "https://github.com/ScaryFrogg/SosAppServer",
                "text": "Server code"
            }
        ],
        "technologies": [
            tech.java,
            tech.kotlin,
            tech.ktor,
        ],
    },
    {
        "id": 2,
        "title": "Book store LAMP-stack",
        "keys": "javascript php sql mysql bootstrap js ajax lamp",
        "description": ["Online book shop made with PHP, MySQL, Bootstrap, JS, AJAX"],
        "image": "/img/portfolio/bukstor.png",
        "ribbon": ["LAMP"],
        "alt": "alt",
        "buttons": [
            {
                "src": "https://github.com/ScaryFrogg/BookStoreLAMP",
                "text": "Source code"
            }
        ],
        "technologies": [
            tech.php,
            tech.js,
        ],
    },
    {
        "id": 3,
        "title": "Python scripts",
        "keys": "python",
        "description": ["Python projects: Web scrapper, Twitter bot, RSA cryptosystems"],
        "image": "/img/portfolio/python.png",
        "ribbon": ["Python"],
        "alt": "alt",
        "buttons": [
            {
                "src": "https://github.com/ScaryFrogg?utf8=%E2%9C%93&tab=repositories&q=&type=&language=python",
                "text": "View repositories"
            }
        ],
        "technologies": [
            tech.python,
        ],
    },
    {
        "id": 5,
        "title": "Clothing store Vue3 MEVN-stack",
        "keys": "JS Vue MEVN recent Vue3 Express Node Mongo",
        "description": ["Clothing store for a small local shop in Serbia. Made using MEVN stack using Vue version 3 and Prime Vue ",
         " Currently site is taken down because store went out of business ",
         " If you are interested in this project contact me and I will temporary host it for you."],
            "image": "/img/portfolio/strks.png",
            "ribbon": ["MEVN"],
            "alt": "alt",
            "buttons": [
                // {
                //     "src": "https://strks.rs/",
                //     "text": "Visit site"
                // }
            ],
            "technologies": [
                tech.vue,
                tech.nodejs,
                tech.js,
            ],
    },
    {
        "id": 6,
        "title": "Shopark MEVN-stack",
        "keys": "Vue JS MEVN recent Express Node Nginx Mongo",
        "description": ["This project consists of 2 client sides built in Vue2. One is for built customers to find places to eat in Hong Kong and if possible can order food direclty from restaurant. Other client is made for store owners and employees to manage stores.",
        " API is build with express and mongoDb and hosted using Nginx ",
        " Project is very feature rich and has a lot of functionallity under the hood, some of which are: PWA, Locations, Push notifications, Translation, Server Sent Events, and much more"],
        "image": "/img/portfolio/tiger.png",
        "ribbon": ["MEVN"],
        "alt": "alt",
        "buttons": [
            {
                "src": "https://www.shoparker.com/",
                "text": "Visit customer site"
            },
            {
                "src": "#",
                "text": "Admin site not yet publicly available"
            }
        ],
        "technologies": [
            tech.vue,
            tech.nodejs,
            tech.js,
        ],
    }

    /* 
    {
        "id": "test",
        "title": "Recent test project python",
        "keys": "Recent test project python",
        "description": "Python projects: Web scrapper, Twitter bot, RSA cryptosystems",
        "image": "/img/portfolio/python.png",
        "ribbon": ["Python"],
        "alt": "alt",
        "buttons": [
            {
                "src": "https://github.com/ScaryFrogg?utf8=%E2%9C%93&tab=repositories&q=&type=&language=python",
                "text": "View repositories"
            }
        ],
        "technologies": [
            tech.android,
            tech.firebase,
            tech.vue
        ],
    }, 
    */
]

export default projects