import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import Project from "@/views/Project.vue";
import ThisIsFine from "@/views/ThisIsFine.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/project/:id",
    name: "Project",
    component: Project,
    props: true,
  },
  {
    path: "/:catchAll(.*)",
    name: "This is fine",
    component: ThisIsFine,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;