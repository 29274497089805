<template>
  <header>
        <router-link to="/" class="logo" id="home">
            VP
        </router-link>
   
    </header>
</template>
<script>
/* 
     <nav>
            <svg width="40" height="40" id="hamburger">
                <rect class="burgerLine1" fill="#fff" height="5" stroke="#000000" width="39" x="0.5" y="29.5" />
                <rect class="burgerLine2" fill="#fff" height="5" stroke="#000000" width="39" x="0.5" y="17.5" />
                <rect class="burgerLine3" fill="#fff" height="5" stroke="#000000" width="39" x="0.5" y="5.5" />
            </svg>
            <ul id="menue" class="OpenClose">
                <li id="li2" onclick="OpenClose()"><a href="#aboutme-section">About me</a></li>
                <li id="li3" onclick="OpenClose()"><a href="#portfolio-section">Portfolio</a></li>
                <li id="li4" onclick="OpenClose()"><a href="#contact-section">Contact</a></li>
            </ul>
        </nav>
 */
</script>
