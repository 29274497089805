<template>
  <Header />
  <router-view />
  <Footer />
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

//css
import "./assets/css/styles.css";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>
