<template>
  <section class="wellcome-section">
    <p class="msg">
      Hello, my name is <span>Vojin Puric</span>, I'm a software developer.
    </p>
    <a href="#aboutme-section" v-smooth-scroll>About me </a>
    <a href="#portfolio-section" v-smooth-scroll>Check out my work </a>
  </section>

  <Particles />
  <About />
  <Portfolio />

  <section class="pull-down" id="contact-section">
    <svg
      preserveAspectRatio="none"
      viewBox="0 0 100 100"
      height="95"
      width="100%"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0 L50 100 L100 0 Z" fill="#B1B5C8"></path>
    </svg>
    <h2>Contact</h2>
    <form method="POST" action="https://formspree.io/puricvojin@gmail.com">
      <label for="mail">Your email</label>
      <input
        type="email"
        name="email"
        id="mail"
        required
        placeholder="Where can I respond to you"
      />
      <label for="subject">Your subject</label>
      <input
        id="subject"
        name="_subject"
        type="text"
        placeholder="e.g. I would like to hire you"
      />
      <label for="message">Your message</label>
      <textarea
        name="message"
        id="message"
        cols="30"
        rows="10"
        required
        placeholder="This field is required"
      ></textarea>
      <button type="submit" value="send">Send</button>
      <p class="msg">
        If my contact form is not working or you wish to contact me using email use this
        address <span>puricvojin@gmail.com</span>
      </p>
    </form>
  </section>
</template>
<script>
import Particles from "../components/Particles.vue";
import About from "../components/About.vue";
import Portfolio from "../components/Portfolio.vue";

export default {
  components: {
    Particles,
    About,
    Portfolio,
  },
};
</script>
