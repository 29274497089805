<template>
  <footer>
    <p>&copy;Vojin Purić 2019</p>
  </footer>
</template>

<style scoped>
footer {
  position: relative;
  z-index: 10;
  height: 45px;
  background-image: linear-gradient(var(--main-bg-color), rgba(10, 10, 10, 0.95));
  font-size: 0.48em;
  color: var(--svetla-boja);
  text-align: center;
  padding-top: 10px;
}
</style>
