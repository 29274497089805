<template>
  <section
    id="portfolio-section"
    style="background-color: var(--light-bg-color)"
  >
    <h2>Portfolio</h2>
    <div
      class="search-bar"
      @focusout="searchToggled = false"
      @focus="searchFocus"
      tabindex="0"
    >
      <span
        @focusout="searchToggled = false"
        @focus="searchFocus"
        tabindex="0"
        id="input-bar"
        style="font-size: 1.3rem; border: 0"
        role="textbox"
        v-text="searchTerm"
      ></span>
      <div class="glass-icon">
        <div
          class="glass-icon-c"
          v-bind:class="{ active: searchToggled, flash: searchToggled }"
        >
          <span class="glass-icon__circle"></span>
          <span class="glass-icon__stick"></span>
        </div>
      </div>
    </div>
    <input
      style="opacity: 0; width: 1px; height: 1px"
      ref="search"
      v-model="searchTerm"
    />
    <!-- Search icons bar -->
    <div class="searchIconsBar">
      <div
        :class="{ shadedIcon: shadedIcon(t) }"
        v-for="t in tech"
        :key="t.name"
        class="searchIcon"
      >
        <img
          width="48"
          height="48"
          :src="t.icon"
          :alt="t.name"
          @click="searchTerm = t.name"
        />
      </div>
    </div>

    <div class="portfolio">
      <div
        v-for="project in projects"
        :key="project.id"
        class="thumbnail"
        :class="{ alt: project.alt !== '' }"
        :style="{ 'background-image': `url(${project.image})` }"
      >
        <router-link :to="{ name: 'Project', params: { id: project.id } }">
          <div :class="{ alt: project.alt !== '' }" class="ribbon">
            <span v-for="(ribbon, index) in project.ribbon" :key="index">
              {{ ribbon }} <br />
            </span>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import tech from "../../technologies.js";
const technologies = tech;
export default {
  setup() {
    const tech = technologies;
    const store = useStore();
    const searchToggled = ref(false);
    const searchTerm = ref("");
    const search = ref(null);
    const projects = computed(() =>
      store.state.projects.filter((project) => {
        //fillter out JS for Java search
        let term =
          searchTerm.value.toLowerCase() != "java"
            ? searchTerm.value.toLowerCase()
            : " java ";

        return project.keys.toLowerCase().includes(term);
      })
    );
    const shadedIcon = (icon) => {
      return searchTerm.value != icon.name && searchTerm.value != "";
    };
    onMounted(() => {});
    function searchFocus() {
      search.value.focus();
      searchToggled.value = true;
    }
    return {
      searchTerm,
      projects,
      searchToggled,
      searchFocus,
      search,
      shadedIcon,
      tech,
    };
  },
};
</script>

<style scoped>
.search-bar:focus {
  border: none;
  outline: none;
}
.alt {
  background-color: var(--main-bg-color);
}
.ribbon.alt {
  background-color: var(--hilight-color);
}
.glass-icon {
  float: right;
}
.thumbnail a {
  width: 100%;
  display: block;
  height: 100%;
}
.searchIconsBar {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}
.searchIcon {
  opacity: 1;
  cursor: pointer;
  transition: 0.7s;
}
.shadedIcon {
  opacity: 0.4;
}
.search-bar::after {
  content: "X";
  width: 100%;
  text-align: right;
  font-size: 1rem;
  padding-right: 0.5em;
}
</style>
