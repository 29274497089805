<template>
  <section id="aboutme-section" style="background-color: var(--light-bg-color)">
    <svg
      preserveAspectRatio="none"
      viewBox="0 0 100 100"
      height="75"
      width="100%"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0 L50 100 L100 0 Z" fill="#252934"></path>
    </svg>
    <h2>About me</h2>
    <img src="/img/avatar.png" id="portrait" />
    <div class="bio">
      I am based in Serbia and I love to build software.
      <br />Technologies I use:
      <div class="bio-columns">
        <ul>
          Web
          <li>Vue</li>
          <li>HTML/CSS/Bootstrap</li>
          <li>JS/Jquery</li>
          <li>PHP</li>
          <li>Kotlin-Ktor</li>
        </ul>
        <ul>
          Android
          <li>Kotlin</li>
          <li>Java</li>
          <li>MVVM</li>
          <li>Architecture Components</li>
        </ul>
        <ul>
          Others
          <li>Linux</li>
          <li>Python</li>
          <li>Nginx</li>
          <li>Firebase</li>
        </ul>
      </div>
    </div>
    <div class="links">
      <a target="_blank" href="https://github.com/scaryfrogg"
        ><img src="/img/githubicon.png" alt="GitHub"
      /></a>
      <a target="_blank" href="/res/vojin_puric_cv.pdf"
        ><img src="/img/cv.svg" alt="CV"
      /></a>
    </div>
  </section>
</template>
<script>
export default {
    
}
</script>