import { createStore } from "vuex"
import projects from "../../projects.js"

const store = createStore({
    state: {
        projects: projects
    },
    getters: {
        getById: (state) => (id) => {
            const result = state.projects.find(p => p.id == id) || { error: 404 }
            return result
        },
    },
    mutations: {},
    actions: {},
    modules: {}
})

export default store