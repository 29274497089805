<template>
  <div id="particles-js"></div>
  <VueParticle domId="particles-js" :config="particleConfig" />
</template>
<script>
import VueParticle from "vue-particlejs";

export default {
  name: "app",
  components: {
    VueParticle,
  },
  data: function () {
    return {
      particleConfig: {
        particles: {
          number: {
            value: 150,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: {
            value: "#fff",
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#ff0000",
            },
          },
          opacity: {
            value: 1,
            random: true,
            anim: {
              enable: true,
              speed: 0.5,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 3,
            random: true,
          },
          line_linked: {
            enable: false,
          },
          move: {
            enable: true,
            speed: 2,
    
          },
          array: [],
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: { enable: false },
            onclick: { enable: true, mode: "push" },
            resize: true,
          },
        },
      },
    };
  },
};
</script>
