<template>
  <section>
    <h2>404 This is fine</h2>

    <video id="fine-video" preload="auto" autoplay loop muted>
      <source src="@/assets/thisisfine.mp4" type="video/mp4" />
    </video>
  </section>
</template>

<style scoped>
body {
  height: 100vh;
}
#fine-video {
  width: 100%;
  background-size: cover;
}
section {
  background-color: var(--main-bg-color);
  width: 100%;
  min-height: 90vh;
  position: relative;
}
h2 {
  font-size: 2em;
  position: absolute;
  top: 5%;
  left: 50%;
  color: var(--hilight-color);
  text-shadow: 3px 3px 2px var(--main-bg-color);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media (orientation: portrait) {
  section {
    height: 90vh;
  }
  h2 {
    font-size: 1.2em;
    position: unset;
    transform: unset;
    -webkit-transform: unset;
    -moz-transform: unset;
    -ms-transform: unset;
  }
}
</style>
