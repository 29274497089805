<template>
  <section>
    <div v-if="project">
      <h1>{{ title }}</h1>

      <img class="project-image" :src="project.image" />
      
      <div class="btn-container" v-if="project.buttons.length > 0">
        <a class="btn" v-for="btn in project.buttons" :key="btn.src" :href="btn.src" target="_blank">
          <div class="btn">{{btn.text}}</div>
        </a>
      </div>
      <ul v-if="project.technologies">
        <li v-for="(tech, i) in project.technologies" :key="i">
          <img class="tech-icon" :src="tech.icon" :alt="tech.name" />{{
            tech.name
          }}
        </li>
      </ul>
      <div class="description">
        <p style="margin-top:1rem" v-for="(t,i) in project.description" :key="i" v-text="t"></p>
      </div>
    </div>
  </section>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  props: {
    id: String,
  },
  setup(props) {
    const title = computed(() => {
      if (typeof project.value.id == "undefined") return "";
      if (project.value.title.toLowerCase().indexOf("recent") == 0) {
        return project.value.title.substr(6);
      } else return project.value.title;
    });
    const store = useStore();
    const project = computed(() => {
      const response = store.getters.getById(props.id);
      if (response.error == 404) useRouter().push({ path: "/404" });
      return response;
    });
    return {
      project,
      title,
    };
  },
};
</script>
<style scoped>
section {
  min-height: 90vh;
  background-color: var(--main-bg-color);
}
h1 {
  text-align: center;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
}
.project-image {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 25px;
  margin: 2rem 0;
}
li {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 0.75em;
  color: var(--svetla-boja);
}
.tech-icon {
  width: 60px;
  height: 60px;
}
.btn-container {
  margin: 2rem 0;
  flex-wrap: wrap;
  display: flex;
  gap: 1em;
  justify-content: center;
}
.btn {
  background-color: var(--form-color);
  padding: 0.4rem;
  white-space: nowrap;
  font-size: 1rem;
  display: block;
  border-radius: 20px;
  color: var(--svetla-boja);
}
.description {
  display: block;
  width: 70%;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 2em;
  font-size: 1rem;
  background-color: var(--form-color);
}
</style>
