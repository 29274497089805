const tech = {
    js:{
        "name": "JavaScript",
        "icon": "/img/icons/ic_js.svg"
    },
    java:{
        "name": "Java",
        "icon": "/img/icons/ic_java.svg"
    },
    kotlin:{
        "name": "Kotlin",
        "icon": "/img/icons/ic_kotlin.svg"
    },
    ktor:{
        "name": "Ktor",
        "icon": "/img/icons/ic_ktor.svg"
    },
    python:{
        "name": "Python",
        "icon": "/img/icons/ic_python.svg"
    },
    android:{
        "name": "Android",
        "icon": "/img/icons/ic_android.svg"
    },
    firebase:{
        "name": "Firebase",
        "icon": "/img/icons/ic_firebase.svg"
    },
    vue:{
        "name": "Vue",
        "icon": "/img/icons/ic_vue.svg"
    },
    php:{
        "name": "PHP",
        "icon": "/img/icons/ic_php.svg"
    },
    nodejs:{
        "name":"node",
        "icon":"/img/icons/ic_nodejs.svg"
    },
}

export default tech